@import "styles/media-queries";

.projects-container {
  margin: auto;
  width: var(--content-width);
  a {
    color: unset;
    border-bottom: 1px dashed #999;
    text-decoration: none;
  }
  a.project-cta {
    width: fit-content;
    margin-top: 1rem;
    font-weight: 500;
    color: #444;
  }
  .project-section {
    display: flex;
    margin: 4em 0;
    @include for-media(phone, tablet-portrait) {
      flex-direction: column-reverse !important;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .project-section-heading {
    width: 95%;
    margin: 1em auto;
    font-family: var(--font-family-serif);
    color: #333;
  }
  .project-info {
    display: flex;
    justify-content: center;
    flex: 1;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 100%;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    @include for-media(phone, tablet-portrait) {
      border-top: none;
      width: 90%;
      padding: 2em 0;
    }
  }
  .project-header {
    font-family: var(--font-family-serif);
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--font-color-dark-gray);
  }
  .project-description {
    line-height: 1.8rem;
    color: var(--font-color-gray);
  }
  .project-graphic {
    flex: 1;
    display: flex;
    justify-content: center;
    img {
      width: 90%;
      @include for-media(phone, tablet-portrait) {
        width: 95%;
      }
    }
  }
  .project-tech {
    font-size: 2rem;
    margin-top: 0.5rem;
    i {
      margin-right: 0.5rem;
    }
  }
}
