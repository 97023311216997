$breakpoints-min: (
  phone : 0px, 
  tablet-portrait : 600px,
  tablet-landscape : 900px,
  desktop: 1200px,
  desktop-large: 1600px,
  desktop-extra-large: 1920px
);

$breakpoints-max: (
  phone : 599px, 
  tablet-portrait : 899px,
  tablet-landscape : 1199px,
  desktop: 1599px,
  desktop-large: 1919px,
  desktop-extra-large: 2999px
);

@mixin for-media($keys...){
  @each $key in $keys {
    @media (min-width: map-get($breakpoints-min, $key)) and (max-width: map-get($breakpoints-max, $key)) { @content; } 
  }
}
