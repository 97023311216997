@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500|Merriweather:400,700&display=swap);
:root{--font-family-sans-serif: "Fira Sans";--font-family-serif: "Merriweather";--font-color-gray: #666666;--font-color-dark-gray: #333333;--content-width: 960px}@media (min-width: 0px) and (max-width: 599px){:root{--content-width: 90%}}@media (min-width: 600px) and (max-width: 899px){:root{--content-width: 90%}}body{margin:0;font-family:"Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;height:100vh}#root{height:inherit}

.calm-footer{height:5rem;display:flex;background-color:black}.calm-footer .footer-content{display:flex;align-items:center;justify-content:center;width:var(--content-width);margin:auto}.calm-footer .calm-logo img{height:2.4rem;-webkit-filter:opacity(0);filter:opacity(0)}.calm-footer .calm-logo span{font-size:2.5rem;color:#EEEEEE}.calm-footer .footer-separator{flex:2 1}

.calm-header{height:5rem;display:flex}.calm-header .header-content{display:flex;align-items:center;justify-content:center;width:var(--content-width);margin:auto}.calm-header .calm-logo{display:flex;align-items:center;flex:1 1}.calm-header .calm-logo img{height:2.4rem;-webkit-filter:opacity(0.7);filter:opacity(0.7)}.calm-header .calm-logo span{font-size:2.5rem;color:#666666}.calm-header .header-separator{flex:1 1}@media (min-width: 0px) and (max-width: 599px){.calm-header .header-separator{flex:0 1}.calm-header .nav-links{flex:3 1}}@media (min-width: 600px) and (max-width: 899px){.calm-header .header-separator{flex:0 1}.calm-header .nav-links{flex:3 1}}@media (min-width: 900px) and (max-width: 1199px){.calm-header .header-separator{flex:0 1}.calm-header .nav-links{flex:3 1}}

.nav-links{display:flex;justify-content:space-around;flex:1 1}.nav-links a{text-transform:uppercase;text-decoration:unset;font-size:0.9em;font-weight:500;outline:none}.nav-links a:hover::before,.nav-links a:hover::after{opacity:1;transform:translateX(0px)}.nav-links a::before,.nav-links a::after{display:inline-block;opacity:0;transition:transform 0.3s, opacity 0.2s}.nav-links a::before{margin-right:5px;content:"[";transform:translateX(10px)}.nav-links a::after{margin-left:5px;content:"]";transform:translateX(-10px)}.nav-links.dark a{color:var(--font-color-gray)}.nav-links.light a{color:#dddddd}

.social-links a{color:#dddddd;padding:0 0.5em}.social-links i{font-size:1.12em}

.burger span{display:block;width:33px;height:4px;margin-bottom:5px;position:relative;background:#666666;border-radius:3px;z-index:1;transform-origin:4px 0px;transition:transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),opacity 0.55s ease}.burger span:last-child{transform-origin:0% 100%}.burger.open span{opacity:1;transform:rotate(45deg) translate(-2px, -1px);background:#666666}.burger.open span:nth-last-child(2){opacity:0;transform:rotate(0deg) scale(0.2, 0.2)}.burger.open span:nth-last-child(1){transform:rotate(-45deg) translate(0, -1px)}.menu{position:absolute;left:0;width:80%;display:flex;justify-content:space-around;top:0;background-color:white;transform:translateY(-100%);transition:transform 0.3s, opacity 0.2s}.menu a{text-transform:uppercase;text-decoration:unset;font-size:0.9em;font-weight:500;outline:none;color:var(--font-color-gray);padding:2em 0}.menu.open{transform:none}

.contact-container{width:var(--content-width);margin:auto}.contact-container h1{font-family:var(--font-family-serif)}.contact-container .contact-item{display:flex;align-items:center;font-size:1.5em;margin:1em 0}.contact-container .contact-item i{color:#666666;margin-right:1em;min-width:1.5rem;transition:0.3s all ease}.contact-container .contact-item a{color:#444444;border-bottom:1px dashed #999;text-decoration:none;margin-right:1em;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.contact-container .contact-item span{opacity:0;transform:translateX(-1em);transition:0.3s all ease;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}@media (min-width: 0px) and (max-width: 599px){.contact-container .contact-item span{display:none}}@media (min-width: 600px) and (max-width: 899px){.contact-container .contact-item span{display:none}}.contact-container .contact-item:hover span{opacity:0.4;transform:translateX(0)}.contact-container .contact-item:hover i.fa-angellist{color:#000000}.contact-container .contact-item:hover i.fa-linkedin-in{color:#0077b5}.contact-container .contact-item:hover i.fa-envelope{color:#dc574b}.contact-container .contact-item:hover i.fa-skype{color:#28a8ea}

.home-container{width:var(--content-width);margin:4em auto}.home-container .home-content{font-family:var(--font-family-serif);padding-top:5rem}.home-container .home-content a{color:unset;border-bottom:1px dashed #999;text-decoration:none}.home-container .home-content p:first-child{margin-top:0.8rem;color:#95b1d0;font-size:1.7rem}.home-container .home-content p:last-child{font-size:1.12em;color:#444;line-height:2rem;text-align:justify}@media (min-width: 0px) and (max-width: 599px){.home-container .home-content{padding-top:1rem}}@media (min-width: 600px) and (max-width: 899px){.home-container .home-content{padding-top:1rem}}@media (min-width: 900px) and (max-width: 1199px){.home-container .home-content{padding-top:1rem}}.home-container .home-hero{width:60%;float:left;margin-right:-1em;-webkit-filter:saturate(0.7);filter:saturate(0.7)}@media (min-width: 0px) and (max-width: 599px){.home-container .home-hero{width:100%;float:none}}@media (min-width: 600px) and (max-width: 899px){.home-container .home-hero{width:100%;float:none}}@media (min-width: 900px) and (max-width: 1199px){.home-container .home-hero{width:100%;float:none}}@media (min-width: 0px) and (max-width: 599px){.home-container{margin:4em auto}}@media (min-width: 600px) and (max-width: 899px){.home-container{margin:4em auto}}@media (min-width: 900px) and (max-width: 1199px){.home-container{margin:4em auto}}

.projects-container{margin:auto;width:var(--content-width)}.projects-container a{color:unset;border-bottom:1px dashed #999;text-decoration:none}.projects-container a.project-cta{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-top:1rem;font-weight:500;color:#444}.projects-container .project-section{display:flex;margin:4em 0}@media (min-width: 0px) and (max-width: 599px){.projects-container .project-section{flex-direction:column-reverse !important}}@media (min-width: 600px) and (max-width: 899px){.projects-container .project-section{flex-direction:column-reverse !important}}.projects-container .project-section:nth-child(even){flex-direction:row-reverse}.projects-container .project-section-heading{width:95%;margin:1em auto;font-family:var(--font-family-serif);color:#333}.projects-container .project-info{display:flex;justify-content:center;flex:1 1}.projects-container .info-container{display:flex;flex-direction:column;justify-content:center;width:80%;height:100%;border-top:1px solid #eee;border-bottom:1px solid #eee}@media (min-width: 0px) and (max-width: 599px){.projects-container .info-container{border-top:none;width:90%;padding:2em 0}}@media (min-width: 600px) and (max-width: 899px){.projects-container .info-container{border-top:none;width:90%;padding:2em 0}}.projects-container .project-header{font-family:var(--font-family-serif);font-size:2rem;margin-bottom:1rem;color:var(--font-color-dark-gray)}.projects-container .project-description{line-height:1.8rem;color:var(--font-color-gray)}.projects-container .project-graphic{flex:1 1;display:flex;justify-content:center}.projects-container .project-graphic img{width:90%}@media (min-width: 0px) and (max-width: 599px){.projects-container .project-graphic img{width:95%}}@media (min-width: 600px) and (max-width: 899px){.projects-container .project-graphic img{width:95%}}.projects-container .project-tech{font-size:2rem;margin-top:0.5rem}.projects-container .project-tech i{margin-right:0.5rem}

.route-container{min-height:calc(100vh - 10rem);display:flex}

