@import 'styles/media-queries';
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500|Merriweather:400,700&display=swap");

:root {
  --font-family-sans-serif: "Fira Sans";
  --font-family-serif: "Merriweather";
  --font-color-gray: #666666;
  --font-color-dark-gray: #333333;
  --content-width: 960px;
  @include for-media(phone, tablet-portrait) {
    --content-width: 90%;
  }
}

body {
  margin: 0;
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: inherit;
}